import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  FaTshirt,
  FaUserTie,
  FaFemale,
  FaTelegramPlane,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaRunning,
  FaCloudRain,
  FaArrowRight,
} from 'react-icons/fa';
import { GiTrousers, GiDress, GiClothes } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Анимация для элементов
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Глобальные стили
const GlobalStyles = styled.div`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    html,
    body {
      overflow-x: hidden;
    }
  }
`;

const HomeContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  padding-top: 42px;
  max-width: 100vw;
  overflow-x: hidden;
`;

// Стили для секций
const Section = styled.section`
  padding: 60px 20px;
  text-align: center;
  animation: ${fadeIn} 1s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const WelcomeSection = styled(Section)`
  background-color: #f9f9f9;
`;

const WelcomeImage = styled.img`
  width: 35%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
    max-width: none;
    border-radius: 10px;
  }
`;

const WelcomeTextContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    text-align: center;
    padding-left: 0;
  }
`;

const WelcomeText = styled.h1`
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
`;

const WelcomeSubText = styled.h2`
  font-size: 28px;
  color: #555;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const ClothingIcon = styled(FaTshirt)`
  margin-right: 10px;
  color: #333;
  font-size: 32px;
`;

const CatalogButton = styled(Link)`
  display: inline-block;
  margin: 20px auto;
  padding: 12px 25px;
  background-color: #ff4500;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap;

  &:hover {
    background-color: #e03e00;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    padding: 10px 20px;
    margin-top: 15px;
  }
`;

const MobileIcon = styled(FaArrowRight)`
  @media (max-width: 768px) {
    font-size: 16px;
    color: #fff;
  }
`;

// Секция с категориями товаров
const CategorySection = styled(Section)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 50px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const CategoryCard = styled.div`
  max-width: 250px;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }

  h3 {
    font-size: 20px;
    color: #333;
    margin: 10px 0;
    word-wrap: break-word;
  }

  .hidden-text {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

const CategoryIcon = styled.div`
  font-size: 40px;
  margin-bottom: 10px;
`;

// SEO скрытый текст
const HiddenTextSEO = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

// Секция с оплатой
const PaymentSection = styled(Section)`
  background-color: #f9f9f9;
  flex-direction: column;
`;

const PaymentTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const PaymentText = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;

  a {
    color: #1da1f2;
    text-decoration: none;
  }
`;

// Footer
const Footer = styled.footer`
  background-color: #333;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 20px;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 20px;
`;

const FooterTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const FooterLink = styled.a`
  display: block;
  color: #ddd;
  margin-bottom: 15px;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;

  &:hover {
    color: #fff;
  }
`;

const FooterText = styled.p`
  font-size: 16px;
  color: #ddd;
`;

const SubscribeSection = styled.div`
  margin-top: 40px;
`;

const SubscribeInput = styled.input`
  padding: 12px;
  width: 70%;
  border: none;
  border-radius: 30px;
  margin-right: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const SubscribeButton = styled.button`
  padding: 12px 25px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #555;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #ddd;

  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;

const HomePage: React.FC = () => {
  return (
    <GlobalStyles>
      <Helmet>
        <title>Aifory Brand - Стильний жіночий одяг в Україні</title>
        <meta
          name="description"
          content="Купуйте модний одяг для жінок у магазині Aifory Brand. Пропонуємо великий вибір суконь, спідниць, костюмів та спортивних костюмів."
        />
        <meta
          name="keywords"
          content="купити жіночий одяг, стильний одяг для жінок, костюми, сукні, спідниці, верхній одяг, спортивні костюми, штани"
        />
      </Helmet>
      <HomeContainer>
        {/* SEO скрытый текст */}
        <HiddenTextSEO>
          <p>
            Магазин модного одягу Aifory-Brand пропонує широкий асортимент стильного одягу для жінок та чоловіків. У нас ви знайдете костюми, сукні, спідниці, верхній одяг, спортивні костюми, штани, сорочки та комбінезони. Весь одяг створений з якісних матеріалів, що поєднують комфорт і стиль.
          </p>
          <p>
            Купуйте елегантні костюми для офіційних заходів, жіночі сукні для будь-якої події, стильні спідниці та теплий верхній одяг. Для активного способу життя ми пропонуємо зручні спортивні костюми та модні штани.
          </p>
          <p>
            Ми знаходимося в Києві, Україна. Наші соцмережі: Instagram, Facebook, Telegram. Приєднуйтесь до нас, щоб отримувати останні новини про наші нові колекції.
          </p>
        </HiddenTextSEO>

        {/* Welcome Section */}
        <WelcomeSection>
          <WelcomeImage
            src="/assets/images/home1.jpg"
            alt="Ласкаво просимо до Aifory-Brand"
          />
          <WelcomeTextContainer>
            <WelcomeText>Ласкаво просимо</WelcomeText>
            <WelcomeSubText>
              <ClothingIcon />
              до Aifory-Brand!
            </WelcomeSubText>
          </WelcomeTextContainer>
        </WelcomeSection>

        {/* Секция с категориями товаров */}
        <CategorySection>
          <CategoryCard>
            <CategoryIcon>
              <FaUserTie />
            </CategoryIcon>
            <h3>Костюми</h3>
            <p className="hidden-text">
              Елегантні костюми для офіційних заходів та важливих зустрічей.
            </p>
            <CatalogButton
              to={`/catalog?category=${encodeURIComponent('Костюми')}`}
            >
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <FaFemale />
            </CategoryIcon>
            <h3>Сукні</h3>
            <p className="hidden-text">
              Жіночі сукні для урочистих подій і повсякденного носіння.
            </p>
            <CatalogButton to={`/catalog?category=${encodeURIComponent('Сукні')}`}>
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <GiDress />
            </CategoryIcon>
            <h3>Спідниці</h3>
            <p className="hidden-text">
              Вишукані спідниці для будь-якого стилю.
            </p>
            <CatalogButton
              to={`/catalog?category=${encodeURIComponent('Спідниці')}`}
            >
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <FaCloudRain />
            </CategoryIcon>
            <h3>Верхній одяг</h3>
            <p className="hidden-text">
              Теплий верхній одяг для холодних днів.
            </p>
            <CatalogButton
              to={`/catalog?category=${encodeURIComponent('Верхній одяг')}`}
            >
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <FaRunning />
            </CategoryIcon>
            <h3>Спортивні костюми</h3>
            <p className="hidden-text">
              Зручні спортивні костюми для активного способу життя.
            </p>
            <CatalogButton
              to={`/catalog?category=${encodeURIComponent('Спортивні костюми')}`}
            >
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <GiTrousers />
            </CategoryIcon>
            <h3>Штани</h3>
            <p className="hidden-text">Модні штани для будь-якого випадку.</p>
            <CatalogButton to={`/catalog?category=${encodeURIComponent('Штани')}`}>
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <FaTshirt />
            </CategoryIcon>
            <h3>Сорочки</h3>
            <p className="hidden-text">
              Стильні сорочки для офісу та вечірок.
            </p>
            <CatalogButton
              to={`/catalog?category=${encodeURIComponent('Сорочки')}`}
            >
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>

          <CategoryCard>
            <CategoryIcon>
              <GiClothes />
            </CategoryIcon>
            <h3>Комбінезони</h3>
            <p className="hidden-text">
              Жіночі комбінезони для будь-якої події.
            </p>
            <CatalogButton
              to={`/catalog?category=${encodeURIComponent('Комбінезони')}`}
            >
              <MobileIcon />
              <span>Переглянути</span>
            </CatalogButton>
          </CategoryCard>
        </CategorySection>

        {/* Секция оплаты и доставки */}
        <PaymentSection>
          <PaymentTitle>Оплата та доставка</PaymentTitle>
          <PaymentText>
            Ми пропонуємо два способи оплати для вашої зручності. Після
            оформлення замовлення у кошику, ви можете:
            <br />
            <br />
            <strong>1. Зробити повну оплату або передоплату за доставку:</strong>{' '}
            Надішліть скриншот оплати нашому менеджеру в Telegram для
            підтвердження замовлення.
            <br />
            <br />
            <strong>2. Написати нашому менеджеру в Telegram:</strong> Узгодьте
            всі деталі замовлення та оплату з нашим менеджером через Telegram.
            <br />
            <br />
            <FaTelegramPlane size={20} />{' '}
            <a
              href="https://t.me/aiforybrand"
              target="_blank"
              rel="noopener noreferrer"
            >
              @aiforybrand
            </a>
          </PaymentText>
        </PaymentSection>

        {/* Footer */}
        <Footer>
          <FooterSection>
            <FooterTitle>Про нас</FooterTitle>
            <FooterText>
              Aifory Brand - ваш надійний партнер у світі моди. Ми
              спеціалізуємося на створенні високоякісного одягу, що поєднує
              стиль і комфорт.
            </FooterText>
          </FooterSection>
          <FooterSection>
            <FooterTitle>Навігація</FooterTitle>
            <FooterLink href="/">Головна</FooterLink>
            <FooterLink href="/catalog">Каталог</FooterLink>
            <FooterLink href="/contacts">Контакти</FooterLink>
            <FooterLink href="/about">Про нас</FooterLink>
          </FooterSection>
          <FooterSection>
            <FooterTitle>Підписка на новини</FooterTitle>
            <FooterText>
              Отримуйте останні новини та спеціальні пропозиції прямо на вашу
              електронну пошту.
            </FooterText>
            <SubscribeSection>
              <form>
                <SubscribeInput
                  type="email"
                  placeholder="Введіть вашу електронну пошту"
                />
                <SubscribeButton>Підписатися</SubscribeButton>
              </form>
            </SubscribeSection>
          </FooterSection>
          <FooterSection>
            <FooterTitle>Контакти</FooterTitle>
            <ContactInfo>
              <FaMapMarkerAlt /> Київ, Україна
            </ContactInfo>
            <ContactInfo>
              <FaPhone /> +380 93 697 71 42
            </ContactInfo>
            <ContactInfo>
              <FaEnvelope /> aiforubrand4754@gmail.com
            </ContactInfo>
          </FooterSection>
          <FooterSection>
            <FooterText>© 2024 Aifory Brand. Всі права захищені.</FooterText>
          </FooterSection>
        </Footer>
      </HomeContainer>
    </GlobalStyles>
  );
};

export default HomePage;
