import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  FaMapMarkerAlt,
  FaTruck,
  FaPhone,
  FaEnvelope,
  FaTelegramPlane,
  FaClock,
} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

// Анимация для элементов
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Стили контейнера
const Container = styled.div`
  padding: 100px 20px 60px;
  text-align: center;
  animation: ${fadeIn} 1s ease-in-out;
  background: linear-gradient(120deg, #f1c40f, #2980b9);
  max-width: 100vw;
  overflow-x: hidden;
`;

const Title = styled.h1`
  font-size: 36px;
  color: #fff;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #f9f9f9;
  margin-bottom: 40px;
`;

const IconRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  flex-wrap: wrap;
  gap: 20px;
`;

const IconContainer = styled.div`
  text-align: center;
  color: #fff;
  width: 150px;
`;

const IconLabel = styled.p`
  margin-top: 10px;
  font-size: 18px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const Button = styled.a`
  display: inline-block;
  padding: 15px 30px;
  margin-top: 30px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #0056b3;
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 60px;
  padding: 40px 20px;
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const InfoTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
`;

const InfoText = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const ContactHours = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  color: #555;
  flex-wrap: wrap;
  gap: 20px;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

// SEO скрытый текст
const HiddenTextSEO = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

const Contacts: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>Контакти | Магазин Aifory-Brand</title>
        <meta
          name="description"
          content="Контакти магазину Aifory-Brand. Зв'яжіться з нами для отримання інформації про замовлення та доставку."
        />
        <meta
          name="keywords"
          content="контакти Aifory-Brand, магазин модного одягу, доставка, контакти Київ, жіночий одяг, чоловічий одяг, швидка доставка, Нова Пошта"
        />
      </Helmet>

      {/* SEO скрытый текст */}
      <HiddenTextSEO>
        <p>
          Магазин Aifory-Brand пропонує великий вибір жіночого та чоловічого одягу для будь-яких подій. Наша компанія розташована в Києві і забезпечує швидку доставку по всій території України. Ви можете замовити стильні костюми, сукні, штани, спортивний одяг, верхній одяг і багато іншого з доставкою через "Нову пошту".
        </p>
        <p>
          Контакти: Ви можете звертатися до нас за телефоном +380 93 697 71 42 або написати на email aiforubrand4754@gmail.com. Ми працюємо з 9:00 до 22:00 з понеділка по п'ятницю, а у вихідні дні надаємо онлайн-консультації.
        </p>
        <p>
          Підпишіться на наші сторінки у соціальних мережах: Instagram, Facebook, Telegram, де ви знайдете останні новини, новинки колекцій і ексклюзивні знижки на товари. Також ви можете залишити відгук про наш сервіс на будь-якому з наших соціальних каналів.
        </p>
      </HiddenTextSEO>

      <Title>Контакти</Title>
      <Subtitle>Доставка по всій Україні. Ми завжди на зв'язку, щоб допомогти вам!</Subtitle>

      <IconRow>
        <IconContainer>
          <FaMapMarkerAlt size={40} />
          <IconLabel>Київ, Україна</IconLabel>
        </IconContainer>
        <IconContainer>
          <FaTruck size={40} />
          <IconLabel>Швидка доставка по всій території України</IconLabel>
        </IconContainer>
        <IconContainer>
          <FaPhone size={40} />
          <IconLabel>+380 93 697 71 42</IconLabel>
        </IconContainer>
        <IconContainer>
          <FaEnvelope size={40} />
          <IconLabel>aiforubrand4754@gmail.com</IconLabel>
        </IconContainer>
      </IconRow>

      <Button
        href="https://t.me/aiforybrand"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTelegramPlane size={20} style={{ marginRight: '10px' }} />
        Написати нашому менеджеру
      </Button>

      <AdditionalInfo>
        <InfoTitle>Додаткова інформація</InfoTitle>
        <InfoText>
          Наша компанія співпрацює з "Новою поштою", провідною кур'єрською службою в Україні, для забезпечення швидкої та надійної доставки вашого замовлення.
        </InfoText>
        <InfoText>
          Ми також пропонуємо кілька способів оплати, щоб зробити процес покупки максимально зручним для вас. Зв'яжіться з нами, якщо у вас виникли питання або потрібна додаткова інформація. Ми завжди готові допомогти!
        </InfoText>

        <ContactHours>
          <ContactItem>
            <FaClock size={20} />
            <span>Години роботи: Пн-Пт 9:00 - 22:00</span>
          </ContactItem>
          <ContactItem>
            <FaClock size={20} />
            <span>Вихідні дні: Сб-Нд</span>
          </ContactItem>
        </ContactHours>
      </AdditionalInfo>
    </Container>
  );
};

export default Contacts;
