import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useCart } from '../context/CartContext';
import { FaTrashAlt, FaTimes } from 'react-icons/fa';
import { Tooltip } from '@chakra-ui/react';
import axios from 'axios';

// Анимация для плавного появления
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Анимация для плавного исчезновения
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

// Стили для плавного открытия и закрытия модального окна
const ModalOverlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  animation: ${({ visible }) =>
    visible
      ? css`
          ${fadeIn} 0.5s ease forwards
        `
      : css`
          ${fadeOut} 0.5s ease forwards
        `};
`;

const ModalContent = styled.div<{ visible: boolean }>`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  position: relative;
  animation: ${({ visible }) =>
    visible
      ? css`
          ${fadeIn} 0.5s ease forwards
        `
      : css`
          ${fadeOut} 0.5s ease forwards
        `};
`;

const CartContainer = styled.div`
  padding: 100px 20px;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  background-color: #f0f0f5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const CartTitle = styled.h1`
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const CartItemsList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
`;

const CartItem = styled.li`
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-out;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`;

const CartItemMedia = styled.div`
  display: flex;
  align-items: center;
`;

const CartItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
`;

const CartItemVideo = styled.video`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
`;

const CartItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-right: 20px;
`;

const CartItemName = styled.span`
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
`;

const CartItemColor = styled.span`
  font-size: 1rem;
  color: #333;
  margin-top: 5px;
`;

const CartItemPrice = styled.span`
  font-size: 1.25rem;
  color: #007bff;
  margin-top: 5px;
`;

const CartItemModel = styled.span`
  font-size: 1rem;
  color: #333;
  margin-top: 5px;
`;

const RemoveButton = styled.button`
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #ff4757;
  }
`;

const CheckoutContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const TotalPrice = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const CheckoutButton = styled.button`
  padding: 15px 30px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  font-size: 1.25rem;

  &:hover {
    background-color: #218838;
  }
`;

const FloatingCart = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  padding: 15px 25px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 10px;
  }
`;

const NotificationPopup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out, ${fadeOut} 0.5s ease-out 2.5s;
  z-index: 10000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: #ff4757;
  }
`;

const Cart: React.FC = () => {
  const { cartItems, removeFromCart } = useCart();
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [contactInfo, setContactInfo] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCheckout = () => {
    setModalVisible(true);
  };

  const handleSendOrder = async () => {
    try {
      const message = cartItems
        .map(
          (item) =>
            `Товар: ${item.name}\nЦіна: ₴${item.price}\nКолір: ${item.color}\nМодель: ${item.model}`
        )
        .join('\n\n');

      await axios.post(
        `https://api.telegram.org/bot7412740739:AAE1TmDlMyGqzvrEdSHTisS1HKXQDAKgYM4/sendMessage`,
        {
          chat_id: '-4540939536',
          text: `Новий замовлення:\n${message}\nЗагальна вартість: ₴${totalPrice}\nКонтактна інформація: ${contactInfo}`,
        }
      );

      setShowConfirmation(true); // Показываем уведомление
      setTimeout(() => setShowConfirmation(false), 3000); // Убираем через 3 секунды
      setModalVisible(false); // Закрываем модальное окно
    } catch (error) {
      console.error('Помилка при відправці повідомлення в Telegram', error);
    }
  };

  const handleRemoveItem = (id: number) => {
    removeFromCart(id);
    setNotificationVisible(true);
    setTimeout(() => setNotificationVisible(false), 3000);
  };

  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  return (
    <>
      <CartContainer>
        <CartTitle>Ваш кошик</CartTitle>
        {cartItems.length === 0 ? (
          <p>Ваш кошик порожній...</p>
        ) : (
          <>
            <CartItemsList>
              {cartItems.map((item) => (
                <CartItem key={item.id}>
                  <CartItemMedia>
                  {item.video ? (
                <CartItemVideo autoPlay loop muted>
                  <source src={item.video} type="video/mp4" />
                    Ваш браузер не поддерживает видео.
                      </CartItemVideo>
                         ) : (
                        <CartItemImage src={item.image} alt={item.name} />
                         )}

                  </CartItemMedia>
                  <CartItemDetails>
                    <Tooltip label={item.name}>
                      <CartItemName>{item.name}</CartItemName>
                    </Tooltip>
                    <CartItemColor>Колір: {item.color}</CartItemColor>
                    <CartItemModel>Модель: {item.model}</CartItemModel>
                    <CartItemPrice>₴{item.price}</CartItemPrice>
                  </CartItemDetails>
                  <RemoveButton onClick={() => handleRemoveItem(item.id)}>
                    <FaTrashAlt size={20} />
                  </RemoveButton>
                </CartItem>
              ))}
            </CartItemsList>
            <CheckoutContainer>
              <TotalPrice>Загальна вартість: ₴{totalPrice}</TotalPrice>
              <CheckoutButton onClick={handleCheckout}>Оформити замовлення</CheckoutButton>
            </CheckoutContainer>
          </>
        )}
      </CartContainer>

      <FloatingCart>
        <span>Товарів: {cartItems.length}</span>
        <span>Загалом: ₴{totalPrice}</span>
      </FloatingCart>

      {notificationVisible && <NotificationPopup>Товар видалено з кошика!</NotificationPopup>}

      {modalVisible && (
        <ModalOverlay visible={modalVisible}>
          <ModalContent visible={modalVisible}>
            <h2>Введіть ваш Telegram ID або номер телефону</h2>
            <p>Ми зв'яжемося з вами, щоб підтвердити замовлення та обговорити деталі доставки.</p>
            <input
              type="text"
              placeholder="Telegram або телефон"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              style={{ padding: '10px', width: '100%', marginBottom: '20px', borderRadius: '5px' }}
            />
            <button
              onClick={handleSendOrder}
              style={{
                padding: '10px 20px',
                backgroundColor: '#28a745',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Відправити замовлення
            </button>
            <CloseButton onClick={() => setModalVisible(false)}><FaTimes /></CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}

      {showConfirmation && (
        <NotificationPopup>Замовлення успішно оформлено!</NotificationPopup>
      )}
    </>
  );
};

export default Cart;
