import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaShoppingCart, FaHome, FaList, FaPhone, FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';

// Анимация для элементов на мобильных устройствах
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const NavbarContainer = styled.nav`
  width: 100%;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    padding: 10px;
  }
`;

const NavbarBrand = styled.div`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px; /* Увеличил размер логотипа на мобильных */
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    transition: color 0.3s, transform 0.3s ease-in-out;

    &:hover {
      color: #ff4500;
      transform: scale(1.1); /* Плавное увеличение при наведении */
    }

    svg {
      font-size: 20px; /* Размер иконок для ПК */
    }

    @media (max-width: 768px) {
      svg {
        font-size: 25px; /* Увеличил размер иконок на мобильных устройствах */
        animation: ${fadeIn} 0.3s ease-in-out; /* Плавная анимация появления */
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
`;

const CartCount = styled.span`
  background-color: #ff4500;
  color: #fff;
  border-radius: 50%;
  padding: 2px 8px;
  margin-left: 5px;
  font-size: 14px;
`;

const Navbar: React.FC = () => {
  const { cartItems } = useCart();

  return (
    <NavbarContainer>
      <NavbarBrand>Aifory Brand</NavbarBrand>
      <NavbarLinks>
        <Link to="/" aria-label="Home">
          <FaHome />
        </Link>
        <Link to="/catalog" aria-label="Catalog">
          <FaList />
        </Link>
        <Link to="/contacts" aria-label="Contacts">
          <FaPhone />
        </Link>
        <Link to="/about" aria-label="About Us">
          <FaInfoCircle />
        </Link>
        <Link to="/cart" aria-label="Cart">
          <FaShoppingCart />
          {cartItems.length > 0 && <CartCount>{cartItems.length}</CartCount>}
        </Link>
      </NavbarLinks>
    </NavbarContainer>
  );
};

export default Navbar;
