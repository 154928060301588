import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    background-color: #f8f9fa; /* Светлый фон */
    color: #333; /* Тёмный текст */
    line-height: 1.6;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* Стили для модального окна */
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    max-height: 80vh;
    overflow-y: auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
  }
`;

export default GlobalStyles;
