import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaChevronRight, FaChevronLeft, FaFilter, FaTimes } from 'react-icons/fa';
import { useCart } from '../context/CartContext';
import { useLocation } from 'react-router-dom';

// Анимации и стили
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const modalOpenAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const modalCloseAnimation = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const dropdownOpenAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const dropdownCloseAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const CatalogContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  padding: 60px 20px;
  padding-top: 100px;
  background: linear-gradient(270deg, #ffffff, #ffcf07, #ffffff);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 10s ease infinite;
  min-height: 100vh;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 10px;
    padding-top: 80px;
    font-size: 14px;
  }
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 300px;
  border: 2px solid #333;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FilterButtonContainer = styled.div`
  position: relative;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease;
  font-size: 16px;

  &:hover {
    background-color: #ffdb58;
    color: #007bff;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const FilterDropdown = styled.div<{ $isOpen: boolean; $closing: boolean }>`
  display: ${({ $isOpen, $closing }) => ($isOpen || $closing ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 10;
  width: 200px;
  animation: ${({ $closing }) => ($closing ? dropdownCloseAnimation : dropdownOpenAnimation)} 0.3s forwards;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    transform: none;
  }
`;

const FilterOption = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 колонки */
  gap: 30px; /* Отступы между карточками */
  justify-content: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 колонки на планшетах */
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 колонка на мобильных */
    gap: 10px;
  }
`;

const ProductCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 450px; /* Высота карточки для вытянутой формы */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    margin: 5px;
    padding: 10px;
    height: 400px;
  }
`;

const ProductVideo = styled.video`
  width: 100%;
  height: 280px; /* Высота видео */
  border-radius: 10px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 280px; /* Высота изображения */
  border-radius: 10px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const ProductName = styled.h3`
  margin-top: 10px;
  font-size: 18px;
  color: #333;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ProductPrice = styled.p`
  font-size: 16px;
  color: #555;
  margin-right: 10px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BuyButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease;
  font-size: 16px;

  &:hover {
    background-color: #ffdb58;
    color: #007bff;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

const ModalBackground = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: auto;
`;

const ModalContent = styled.div<{ $closing: boolean; $isMobile: boolean }>`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  animation: ${({ $closing }) => ($closing ? modalCloseAnimation : modalOpenAnimation)} 0.3s forwards;

  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 768px) {
    width: 90%;
    max-height: 90vh;
  }
`;

const ImagesContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-height: 500px;

  @media (max-width: 768px) {
    max-height: 400px;
  }
`;

const ProductImages = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

const ProductModalVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  &:nth-of-type(1) {
    left: 10px;
  }

  &:nth-of-type(2) {
    right: 10px;
  }
`;

const ProductDescription = styled.div`
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 10px;
  }
`;

const ProductDetails = styled.p<{ $isOpen: boolean }>`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  }
`;

const ProductDetailsBold = styled.p`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const DescriptionToggleButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ColorOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const ColorOption = styled.button<{ $isSelected: boolean; $isAvailable: boolean }>`
  padding: 5px 10px;
  border: ${({ $isSelected }) => ($isSelected ? '2px solid #007bff' : '1px solid #ccc')};
  background-color: ${({ $isSelected, $isAvailable }) => ($isSelected && $isAvailable ? '#007bff' : '#fff')};
  color: ${({ $isSelected, $isAvailable }) => ($isSelected && $isAvailable ? '#fff' : $isAvailable ? '#333' : '#aaa')};
  border-radius: 5px;
  cursor: ${({ $isAvailable }) => ($isAvailable ? 'pointer' : 'not-allowed')};

  &:hover {
    background-color: ${({ $isAvailable }) => ($isAvailable ? '#007bff' : '#fff')};
    color: ${({ $isAvailable }) => ($isAvailable ? '#fff' : '#aaa')};
  }
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #ff0000;
  }
`;

const Catalog: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [closingDropdown, setClosingDropdown] = useState(false);
  const [closingModal, setClosingModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState('');
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const { addToCart } = useCart();

  const [searchQuery, setSearchQuery] = useState('');

  const location = useLocation();

  // Получение категории из URL-параметров при загрузке компонента
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const categoryFromUrl = queryParams.get('category');
    if (categoryFromUrl) {
      setSelectedCategory(categoryFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen]);

  const categories = [
    'Сукні',
    'Костюми',
    'Верхній одяг',
    'Спортивні костюми',
    'Штани',
    'Спідниці',
    'Сорочки',
    'Комбінезони',
  ];

  const products = [
    {
      id: 1,
      name: 'Літній білий комбінезон',
      price: 900,
      category: 'Комбінезони',
      image: '/assets/images/mod019/mod019.jpg',
      images: [
        '/assets/images/mod019/mod019q.jpg',
        '/assets/images/mod019/mod019w.jpg',
        '/assets/images/mod019/mod019e.jpg',
        '/assets/images/mod019/mod019r.jpg',
        '/assets/images/mod019/mod019t.jpg',
      ],
      colors: {
        'білий': [
          '/assets/images/mod019/mod019q.jpg',
          '/assets/images/mod019/mod019w.jpg',
          '/assets/images/mod019/mod019e.jpg',
          '/assets/images/mod019/mod019r.jpg',
          '/assets/images/mod019/mod019t.jpg',
        ],
        'чорний': []  // Чорний колір недоступний
      },
      description:
        'Стильний та легкий білий комбінезон із повітряними рукавами, ідеальний для літніх прогулянок. Цей комбінезон виконаний з ніжного батисту, що надає йому легкості та комфорту.',
      sizes: '42-44 та 46-48',
      fabric: 'батист',
      model: 'mod019',
      colorOptions: ['білий'],
    },
    {
      id: 2,
      name: 'Стильний комбінезон',
      price: 750,
      category: 'Комбінезони',
      image: '/assets/images/mod467/mod467black.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod467/mod467black.jpg',
          '/assets/images/mod467/mod467blackq.jpg',
          '/assets/images/mod467/mod467blackw.jpg',
          '/assets/images/mod467/mod467blacke.jpg',
          '/assets/images/mod467/mod467blackr.jpg',
        ],
        'молоко': [
          '/assets/images/mod467/mod467milk.jpg',
          '/assets/images/mod467/mod467milkq.jpg',
          '/assets/images/mod467/mod467milkw.jpg',
          '/assets/images/mod467/mod467milke.jpg',
          '/assets/images/mod467/mod467milkr.jpg',
          '/assets/images/mod467/mod467milkt.jpg',
          '/assets/images/mod467/mod467milky.jpg',
        ],
        'червоний': [
          '/assets/images/mod467/mod467red.jpg',
          '/assets/images/mod467/mod467redq.jpg',
          '/assets/images/mod467/mod467redw.jpg',
          '/assets/images/mod467/mod467rede.jpg',
          '/assets/images/mod467/mod467redr.jpg',
        ],
        'бежевий': [
          '/assets/images/mod467/mod467beige.jpg',
          '/assets/images/mod467/mod467beigeq.jpg',
          '/assets/images/mod467/mod467beigew.jpg',
          '/assets/images/mod467/mod467beigee.jpg',
          '/assets/images/mod467/mod467beiger.jpg',
        ],
        'малиновий': [
          '/assets/images/mod467/mod467raspberry.jpg',
          '/assets/images/mod467/mod467raspberryq.jpg',
          '/assets/images/mod467/mod467raspberryw.jpg',
          '/assets/images/mod467/mod467raspberrye.jpg',
          '/assets/images/mod467/mod467raspberryr.jpg',
          '/assets/images/mod467/mod467raspberryt.jpg',
          '/assets/images/mod467/mod467raspberryy.jpg',
        ],
      },
      description:
        'Наш комбінезон зробить справжній фурор. Стильний, яскравий, жіночний та комфортний. Відкрита спинка та довжина міні. А ще, він ідеальний для будь-яких подій.',
      sizes: '42-44 та 46-48',
      fabric: 'рубчик',
      model: 'mod467',
      colorOptions: ['чорний', 'молоко', 'червоний', 'бежевий', 'малиновий'],
    },
    {
      id: 3,
      name: 'Комбінезон для яскравих моментів',
      price: 750,
      category: 'Комбінезони',
      image: '/assets/images/mod403/mod403black.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod403/mod403black.jpg',
          '/assets/images/mod403/mod403blackq.jpg',
          '/assets/images/mod403/mod403blackw.jpg',
        ],
        'оливковий': [
          '/assets/images/mod403/mod403olive.jpg',
          '/assets/images/mod403/mod403oliveq.jpg',
          '/assets/images/mod403/mod403olivew.jpg',
        ],
        'білий': [
          '/assets/images/mod403/mod403white.jpg',
          '/assets/images/mod403/mod403whiteq.jpg',
          '/assets/images/mod403/mod403whitew.jpg',
          '/assets/images/mod403/mod403whitee.jpg',
        ],
        'лавандовий': [
          '/assets/images/mod403/mod403lavender.jpg',
          '/assets/images/mod403/mod403lavenderq.jpg',
          '/assets/images/mod403/mod403lavenderw.jpg',
        ],
      },
      description:
        'Наш комбінезон – це вибух стилю! Він поєднує жіночність, яскравість і комфорт, привертаючи увагу. Відкрита спинка додає спокусливості, а міні довжина підкреслює вашу красу. Ідеальний для будь-яких подій!',
      sizes: '42-44 та 46-48',
      fabric: 'рубчик',
      model: 'mod403',
      colorOptions: ['чорний', 'оливковий', 'білий', 'лавандовий'],
    },
    {
      id: 4,
      name: 'Елегантна чорна сукня з широкими рукавами',
      price: 900,
      category: 'Сукні',
      image: '/assets/images/mod435/graphite1.jpg',
      colors: {
        'графіт': [
          '/assets/images/mod435/graphite1.jpg',
          '/assets/images/mod435/graphite2.jpg',
          '/assets/images/mod435/graphite3.jpg',
        ],
        'чорний': [
          '/assets/images/mod435/black1.jpg',
          '/assets/images/mod435/black2.jpg',
          '/assets/images/mod435/black3.jpg',
        ],
        'коричневий': [
          '/assets/images/mod435/brown1.jpg',
          '/assets/images/mod435/brown2.jpg',
        ],
      },
      description:
        'Ця чорна сукня чудово підкреслює жіночність і елегантність. Вільні рукави та текстурована тканина створюють витончений і стильний образ для будь-якої події.',
      sizes: '42-44, 46-48',
      fabric: 'рубчик',
      model: 'mod435',
      colorOptions: ['графіт', 'чорний', 'коричневий'],
    },
    {
      id: 5,
      name: 'Елегантність у чорному',
      price: 900,
      category: 'Сукні',
      image: '/assets/images/mod495/black.jpg',
      colors: {
        'молоко': [
          '/assets/images/mod495/white1.jpg',
          '/assets/images/mod495/white2.jpg',
          '/assets/images/mod495/white3.jpg',
          '/assets/images/mod495/white4.jpg',
          '/assets/images/mod495/white5.jpg',
          '/assets/images/mod495/white6.jpg',
          '/assets/images/mod495/white7.jpg',
          '/assets/images/mod495/white8.jpg',
          '/assets/images/mod495/white9.jpg',
          '/assets/images/mod495/white10.jpg',
          '/assets/images/mod495/white11.jpg',
        ],
        'блакитний': [
          '/assets/images/mod495/blue1.jpg',
          '/assets/images/mod495/blue2.jpg',
          '/assets/images/mod495/blue3.jpg',
          '/assets/images/mod495/blue4.jpg',
          '/assets/images/mod495/blue5.jpg',
          '/assets/images/mod495/blue6.jpg',
          '/assets/images/mod495/blue7.jpg',
          '/assets/images/mod495/blue8.jpg',
          '/assets/images/mod495/blue9.jpg',
          '/assets/images/mod495/blue10.jpg',
        ],
        'червоний': [
          '/assets/images/mod495/red1.jpg',
          '/assets/images/mod495/red2.jpg',
          '/assets/images/mod495/red4.jpg',
          '/assets/images/mod495/red5.jpg',
          '/assets/images/mod495/red6.jpg',
          '/assets/images/mod495/red7.jpg',
          '/assets/images/mod495/red9.jpg',
          '/assets/images/mod495/red10.jpg',
          '/assets/images/mod495/red11.jpg',
          '/assets/images/mod495/red12.jpg',
        ],
        'чорний': [
          '/assets/images/mod495/black1.jpg',
          '/assets/images/mod495/black2.jpg',
          '/assets/images/mod495/black3.jpg',
          '/assets/images/mod495/black4.jpg',
          '/assets/images/mod495/black5.jpg',
          '/assets/images/mod495/black6.jpg',
          '/assets/images/mod495/black7.jpg',
          '/assets/images/mod495/black8.jpg',
          '/assets/images/mod495/black9.jpg',
          '/assets/images/mod495/black10.jpg',
        ],
        'барбі': [
          '/assets/images/mod495/barbie1.jpg',
          '/assets/images/mod495/barbie2.jpg',
          '/assets/images/mod495/barbie3.jpg',
          '/assets/images/mod495/barbie4.jpg',
          '/assets/images/mod495/barbie5.jpg',
          '/assets/images/mod495/barbie6.jpg',
        ],
      },
      description:
        'Елегантна чорна сукня з розкльошеним низом. Підкреслена талія створює витончений силует, а коротка довжина додає грайливості. Універсальна модель для повсякденних образів та вечірніх виходів.',
      sizes: '42-44, 46-48',
      fabric: 'рубчик',
      model: 'mod495',
      colorOptions: ['барбі', 'чорний', 'червоний', 'молоко', 'блакитний'],
    },
    {
      id: 6,
      name: 'Елегантність у лаконічності',
      price: 740,
      category: 'Сукні',
      image: '/assets/images/mod296/beige3.jpg',
      colors: {
        'світлий беж': [
          '/assets/images/mod296/beige1.jpg',
          '/assets/images/mod296/beige2.jpg',
          '/assets/images/mod296/beige3.jpg',
          '/assets/images/mod296/beige4.jpg',
          '/assets/images/mod296/beige5.jpg',
          '/assets/images/mod296/beige6.jpg',
          '/assets/images/mod296/beige7.jpg',
          '/assets/images/mod296/beige8.jpg',
          '/assets/images/mod296/beige9.jpg',
          '/assets/images/mod296/beige10.jpg',
          '/assets/images/mod296/beige11.jpg',
          '/assets/images/mod296/beige12.jpg',
        ],
        'чорний': [
          '/assets/images/mod296/black1.jpg',
          '/assets/images/mod296/black2.jpg',
          '/assets/images/mod296/black3.jpg',
          '/assets/images/mod296/black4.jpg',
          '/assets/images/mod296/black5.jpg',
          '/assets/images/mod296/black6.jpg',
          '/assets/images/mod296/black7.jpg',
          '/assets/images/mod296/black8.jpg',
          '/assets/images/mod296/black9.jpg',
          '/assets/images/mod296/black10.jpg',
        ],
        'графіт': [
          '/assets/images/mod296/graphite1.jpg',
          '/assets/images/mod296/graphite2.jpg',
          '/assets/images/mod296/graphite3.jpg',
          '/assets/images/mod296/graphite4.jpg',
          '/assets/images/mod296/graphite5.jpg',
          '/assets/images/mod296/graphite6.jpg',
          '/assets/images/mod296/graphite7.jpg',
          '/assets/images/mod296/graphite8.jpg',
          '/assets/images/mod296/graphite9.jpg',
          '/assets/images/mod296/graphite10.jpg',
          '/assets/images/mod296/graphite11.jpg',
        ],
      },
      description:
        'Це витончене плаття підкреслить фігуру і додасть загадковості. Максі довжина та облягаючий крій створюють ідеальний силует, а відкритий виріз на спині додає сміливості. Підходить для елегантних вечорів та повсякденних образів.',
      sizes: '42-44, 46-48',
      fabric: 'рубчик',
      model: 'mod296',
      colorOptions: ['світлий беж', 'чорний', 'графіт'],
    },
    {
      id: 7,
      name: 'Граціозна спокуса',
      price: 900,
      category: 'Сукні',
      image: '/assets/images/mod493/red1.jpg',
      colors: {
        'червоний': [
          '/assets/images/mod493/red1.jpg',
          '/assets/images/mod493/red2.jpg',
          '/assets/images/mod493/red3.jpg',
        ],
        'чорний': [
          '/assets/images/mod493/black1.jpg',
          '/assets/images/mod493/black2.jpg',
          '/assets/images/mod493/black3.jpg',
          '/assets/images/mod493/black4.jpg',
          '/assets/images/mod493/black5.jpg',
        ],
        'блакитний': [
          '/assets/images/mod493/blue1.jpg',
          '/assets/images/mod493/blue2.jpg',
          '/assets/images/mod493/blue3.jpg',
          '/assets/images/mod493/blue4.jpg',
        ],
        'рожевий': [
          '/assets/images/mod493/pink1.jpg',
          '/assets/images/mod493/pink2.jpg',
          '/assets/images/mod493/pink3.jpg',
          '/assets/images/mod493/pink4.jpg',
        ],
        'бежевий': [
          '/assets/images/mod493/beige1.jpg',
          '/assets/images/mod493/beige2.jpg',
          '/assets/images/mod493/beige3.jpg',
          '/assets/images/mod493/beige4.jpg',
          '/assets/images/mod493/beige5.jpg',
        ],
        'лавандовий': [
          '/assets/images/mod493/lavender1.jpg',
          '/assets/images/mod493/lavender2.jpg',
          '/assets/images/mod493/lavender3.jpg',
          '/assets/images/mod493/lavender4.jpg',
          '/assets/images/mod493/lavender5.jpg',
        ],
      },
      description:
        'Це облягаюче плаття з розрізами і переплетеннями на спині підкреслить вашу фігуру і додасть особливого шарму. Доступне в чорному, червоному та блакитному кольорах. Ідеально підходить для вечірніх виходів та особливих подій.',
      sizes: '42-44, 46-48',
      fabric: 'рубчик',
      model: 'mod493',
      colorOptions: ['червоний', 'чорний', 'блакитний', 'рожевий', 'бежевий', 'лавандовий'],
    },
    {
      id: 8,
      name: 'Квіткове спокусливе плаття',
      price: 980,
      category: 'Сукні',
      image: '/assets/images/mod601/flower1.jpg',
      colors: {
        'білий з сердечками': [
          '/assets/images/mod601/heart1.jpg',
          '/assets/images/mod601/heart2.jpg',
          '/assets/images/mod601/heart3.jpg',
          '/assets/images/mod601/heart4.jpg',
          '/assets/images/mod601/heart5.jpg',
          '/assets/images/mod601/heart6.jpg',
          '/assets/images/mod601/heart7.jpg',
          '/assets/images/mod601/heart8.jpg',
          '/assets/images/mod601/heart9.jpg',
          '/assets/images/mod601/heart10.jpg',
          '/assets/images/mod601/heart11.jpg',
        ],
        'білий з квітами': [
          '/assets/images/mod601/flower1.jpg',
          '/assets/images/mod601/flower2.jpg',
          '/assets/images/mod601/flower3.jpg',
          '/assets/images/mod601/flower4.jpg',
          '/assets/images/mod601/flower5.jpg',
          '/assets/images/mod601/flower6.jpg',
          '/assets/images/mod601/flower7.jpg',
          '/assets/images/mod601/flower8.jpg',
        ],
      },
      description:
        'Це ніжне плаття з відкритими плечима підкреслить жіночність. Легкий матеріал, облягаючий крій і квітковий принт ідеальні для літніх днів. Рюші та об’ємні рукави додають грайливості, а асиметричний низ створює стильний образ.',
      sizes: '42-44, 46-48',
      fabric: 'шифон',
      model: 'mod601',
      colorOptions: ['білий з сердечками', 'білий з квітами'],
    },
    {
      id: 9,
      name: 'Шовкова спокуса',
      price: 820,
      category: 'Сукні',
      image: '/assets/images/mod460/black.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod460/black.jpg',
        ],
        'графіт': [
          '/assets/images/mod460/graphite1.jpg',
          '/assets/images/mod460/graphite2.jpg',
        ],
        'молоко': [
          '/assets/images/mod460/milk.jpg',
        ],
        'шоколадний': [
          '/assets/images/mod460/chocolate.jpg',
        ],
      },
      description:
        'Це елегантне шовкове плаття підкреслить витонченість. Легкий матеріал облягає фігуру, створюючи розкішний силует. Довгі рукави додають шарму. Ідеальне для особливих випадків. Доступне в різних кольорах.',
      sizes: '42-44, 46-48',
      fabric: 'шовк Армані',
      model: 'mod460',
      colorOptions: ['чорний', 'графіт', 'молоко', 'шоколадний'],
    },
    {
      id: 10,
      name: 'Магія вільних рукавів',
      price: 750,
      category: 'Сукні',
      image: '/assets/images/mod436/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod436/black1.jpg',
          '/assets/images/mod436/black2.jpg',
          '/assets/images/mod436/black3.jpg',
        ],
        'червоний': [
          '/assets/images/mod436/red1.jpg',
          '/assets/images/mod436/red2.jpg',
          '/assets/images/mod436/red3.jpg',
        ],
        'білий': [
          '/assets/images/mod436/white1.jpg',
          '/assets/images/mod436/white2.jpg',
          '/assets/images/mod436/white3.jpg',
          '/assets/images/mod436/white4.jpg',
          '/assets/images/mod436/white5.jpg',
        ],
        'бежевий': [
          '/assets/images/mod436/beige.jpg',
        ],
      },
      description:
        'Це плаття з розкльошеними рукавами поєднує елегантність і комфорт. Плавний силует підкреслює талію, а глибокий виріз додає загадковості. Ідеальне для особливих випадків. Доступне в білому, чорному та червоному кольорах.',
      sizes: '42-44, 46-48',
      fabric: 'софт',
      model: 'mod436',
      colorOptions: ['чорний', 'червоний', 'білий', 'бежевий'],
    },
    {
      id: 11,
      name: 'Ідеальний офісний костюм',
      price: 930,
      category: 'Костюми',
      image: '/assets/images/mod200/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod200/black1.jpg',
          '/assets/images/mod200/black2.jpg',
          '/assets/images/mod200/black3.jpg',
        ],
        'бежевий': [
          '/assets/images/mod200/beige1.jpg',
          '/assets/images/mod200/beige2.jpg',
          '/assets/images/mod200/beige3.jpg',
        ],
        'сірий': [
          '/assets/images/mod200/gray1.jpg',
          '/assets/images/mod200/gray2.jpg',
          '/assets/images/mod200/gray3.jpg',
          '/assets/images/mod200/gray4.jpg',
        ],
        'графіт': [
          '/assets/images/mod200/graphite1.jpg',
        ],
      },
      description:
        'Цей офісний костюм підкреслює елегантність і впевненість. Стриманий крій і якісний матеріал створюють бездоганний образ для ділових зустрічей. Доступний у кількох класичних кольорах, ідеальний для сучасної жінки.',
      sizes: '42-44, 46-48, 50-52',
      fabric: 'костюмна тканина',
      model: 'mod200',
      colorOptions: ['чорний', 'бежевий', 'сірий', 'графіт'],
    },
    {
      id: 12,
      name: 'Шикарний костюм із укороченим жакетом',
      price: 1050,
      category: 'Костюми',
      image: '/assets/images/mod479/graphite_white1.jpg',
      colors: {
        'Графіт + білий': [
          '/assets/images/mod479/graphite_white1.jpg',
          '/assets/images/mod479/graphite_white2.jpg',
          '/assets/images/mod479/graphite_white3.jpg',
          '/assets/images/mod479/graphite_white4.jpg',
          '/assets/images/mod479/graphite_white5.jpg',
          '/assets/images/mod479/graphite_white6.jpg',
          '/assets/images/mod479/graphite_white7.jpg',
          '/assets/images/mod479/graphite_white8.jpg',
          '/assets/images/mod479/graphite_white9.jpg',
          '/assets/images/mod479/graphite_white10.jpg',
          '/assets/images/mod479/graphite_white11.jpg',
          '/assets/images/mod479/graphite_white12.jpg',
          '/assets/images/mod479/graphite_white13.jpg',
        ],
      },
      description:
        'Легкий костюм із широкими штанами та укороченим жакетом створює стильний образ. Підходить для повсякденного носіння. Простий крій додає елегантності.',
      sizes: '42-44, 46-48',
      fabric: 'костюмна тканина + бавовна',
      model: 'mod479',
      colorOptions: ['Графіт + білий'],
    },
    {
      id: 13,
      name: 'Легкість кольору',
      price: 870,
      category: 'Костюми',
      image: '/assets/images/mod021/red1.jpg',
      colors: {
        'червоний': [
          '/assets/images/mod021/red1.jpg',
          '/assets/images/mod021/red2.jpg',
          '/assets/images/mod021/red3.jpg',
        ],
        'молочний': [
          '/assets/images/mod021/milk1.jpg',
          '/assets/images/mod021/milk2.jpg',
          '/assets/images/mod021/milk3.jpg',
        ],
        'чорний': [
          '/assets/images/mod021/black1.jpg',
        ],
        'блакитний': [
          '/assets/images/mod021/blue1.jpg',
          '/assets/images/mod021/blue2.jpg',
          '/assets/images/mod021/blue3.jpg',
        ],
      },
      description:
        'Ніжні спідниця і штани у світлих тонах підкреслять витонченість вашого образу. Ідеальні для створення легкого аутфіту. Обирайте між блакитним, білим, червоним або класичним чорним кольором.',
      sizes: '42-44, 46-48',
      fabric: 'костюмна тканина',
      model: 'mod021',
      colorOptions: ['червоний', 'молочний', 'чорний', 'блакитний'],
    },
    {
      id: 14,
      name: 'Стильний домашній костюм',
      price: 950,
      category: 'Спортивні костюми',
      image: '/assets/images/mod024/beige1.jpg',
      colors: {
        'бежевий': [
          '/assets/images/mod024/beige1.jpg',
          '/assets/images/mod024/beige2.jpg',
        ],
        'меланж': [
          '/assets/images/mod024/melange1.jpg',
          '/assets/images/mod024/melange2.jpg',
          '/assets/images/mod024/melange3.jpg',
        ],
      },
      description:
        'Цей затишний костюм поєднує комфорт і стиль. Верх із розкльошеними рукавами та кроп-топ, а також вільні штани на резинці створюють розслаблений та сучасний образ.',
      sizes: '42-44, 46-48',
      fabric: 'костюм - двонитка, топ - Zara',
      model: 'mod024',
      colorOptions: ['бежевий', 'меланж'],
    },
    {
      id: 15,
      name: 'Спортивний костюм "Комфорт"',
      price: 950,
      category: 'Спортивні костюми',
      image: '/assets/images/mod604/gray1.jpg',
      colors: {
        'сірий': [
          '/assets/images/mod604/gray1.jpg',
          '/assets/images/mod604/gray2.jpg',
        ],
        'світло-сірий': [
          '/assets/images/mod604/light_gray1.jpg',
        ],
        'барбі': [
          '/assets/images/mod604/barbie1.jpg',
        ],
      },
      description:
        'Цей спортивний костюм поєднує комфорт і стиль. Виготовлений з м\'якого матеріалу для максимального комфорту. Широкий капюшон та еластичний пояс забезпечують ідеальну посадку.',
      sizes: '42-44, 46-48',
      fabric: 'двонитка',
      model: 'mod604',
      colorOptions: ['сірий', 'світло-сірий', 'барбі'],
    },
    {
      id: 16,
      name: 'Спортивний костюм з капюшоном',
      price: 940,
      category: 'Спортивні костюми',
      image: '/assets/images/mod512/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod512/black1.jpg',
          '/assets/images/mod512/black2.jpg',
        ],
        'блакитний': [
          '/assets/images/mod512/olive1.jpg',
          '/assets/images/mod512/olive2.jpg',
        ],
        'оливковий': [
          '/assets/images/mod512/blue1.jpg',
          '/assets/images/mod512/blue2.jpg',
          '/assets/images/mod512/blue3.jpg',
          '/assets/images/mod512/blue4.jpg',
          '/assets/images/mod512/blue5.jpg',
          '/assets/images/mod512/blue6.jpg',
          '/assets/images/mod512/blue7.jpg',
          '/assets/images/mod512/blue8.jpg',
        ],
        'червоний': [
          '/assets/images/mod512/red1.jpg',
          '/assets/images/mod512/red2.jpg',
        ],
      },
      description:
        'Зручний та стильний спортивний костюм із капюшоном. Доступний у темно-сірому, блакитному та бежевому кольорах. Комплект включає вкорочену толстовку та вільні штани.',
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка петля',
      model: 'mod512',
      colorOptions: ['чорний', 'оливковий', 'блакитний', 'червоний'],
    },
    {
      id: 17,
      name: 'Велюровий спортивний костюм',
      price: 950,
      category: 'Спортивні костюми',
      image: '/assets/images/mod513/blue1.jpg',
      colors: {
        'блакитний': [
          '/assets/images/mod513/blue1.jpg',
          '/assets/images/mod513/blue2.jpg',
        ],
        'графітовий': [
          '/assets/images/mod513/graphite1.jpg',
          '/assets/images/mod513/graphite2.jpg',
        ],
        'барбі': [
          '/assets/images/mod513/barbie1.jpg',
          '/assets/images/mod513/barbie2.jpg',
          '/assets/images/mod513/barbie3.jpg',
          '/assets/images/mod513/barbie4.jpg',
        ],
        'світло-сірий': [
          '/assets/images/mod513/light_gray1.jpg',
          '/assets/images/mod513/light_gray2.jpg',
          '/assets/images/mod513/light_gray3.jpg',
          '/assets/images/mod513/light_gray4.jpg',
          '/assets/images/mod513/light_gray5.jpg',
        ],
        'чорний': [
          '/assets/images/mod513/black1.jpg',
          '/assets/images/mod513/black2.jpg',
          '/assets/images/mod513/black3.jpg',
          '/assets/images/mod513/black4.jpg',
        ],
      },
      description:
        'М\'який та стильний велюровий костюм із капюшоном. Доступний у кількох кольорах. В комплект входять укорочена толстовка та вільні штани з гумкою на поясі та манжетах.',
      sizes: '42-44, 46-48',
      fabric: 'плюшевий велюр Туреччина',
      model: 'mod513',
      colorOptions: ['блакитний', 'графітовий', 'барбі', 'світло-сірий', 'чорний'],
    },
    {
      id: 18,
      name: 'Спортивний костюм з топом',
      price: 950,
      category: 'Спортивні костюми',
      image: '/assets/images/mod472/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod472/black1.jpg',
          '/assets/images/mod472/black2.jpg',
          '/assets/images/mod472/black3.jpg',
          '/assets/images/mod472/black4.jpg',
          '/assets/images/mod472/black5.jpg',
          '/assets/images/mod472/black6.jpg',
          '/assets/images/mod472/black7.jpg',
          '/assets/images/mod472/black8.jpg',
          '/assets/images/mod472/black9.jpg',
          '/assets/images/mod472/black10.jpg',
        ],
        'меланж': [
          '/assets/images/mod472/melange1.jpg',
          '/assets/images/mod472/melange2.jpg',
          '/assets/images/mod472/melange3.jpg',
          '/assets/images/mod472/melange4.jpg',
          '/assets/images/mod472/melange5.jpg',
          '/assets/images/mod472/melange6.jpg',
          '/assets/images/mod472/melange7.jpg',
          '/assets/images/mod472/melange8.jpg',
          '/assets/images/mod472/melange9.jpg',
          '/assets/images/mod472/melange10.jpg',
        ],
      },
      description:
        'Стильний спортивний костюм, що включає укорочену толстовку, штани та топ. Доступний у світло-сірому та чорному кольорах. Ідеальний для повсякденного носіння або активного відпочинку.',
      sizes: '42-44, 46-48',
      fabric: 'двонитка',
      model: 'mod472',
      colorOptions: ['чорний', 'меланж'],
    },
    {
      id: 19,
      name: 'Біла мереживна спідниця',
      price: 880,
      category: 'Спідниці',
      image: '/assets/images/mod011/white1.jpg',
      colors: {
        'білий': [
          '/assets/images/mod011/white1.jpg',
          '/assets/images/mod011/white2.jpg',
          '/assets/images/mod011/white3.jpg',
        ],
      },
      description:
        'Легка та повітряна біла спідниця з мереживною обробкою та багатошаровим дизайном. Відмінний вибір для створення жіночного та романтичного образу.',
      sizes: '42-44, 46-48',
      fabric: 'прошва',
      model: 'mod011',
      colorOptions: ['білий'],
    },
    {
      id: 20,
      name: 'Джинсова спідниця з необробленим краєм',
      price: 820,
      category: 'Спідниці',
      image: '/assets/images/mod020/denim1.jpg',
      colors: {
        'джинс': [
          '/assets/images/mod020/denim1.jpg',
          '/assets/images/mod020/denim2.jpg',
          '/assets/images/mod020/denim3.jpg',
        ],
      },
      description:
        'Стильна джинсова спідниця з високою талією та необробленим краєм. Поєднує в собі класичний денім і сучасний дизайн, підкреслюючи талію та створюючи ефектний образ.',
      sizes: '42-44, 46-48',
      fabric: 'джинс Туреччина + бавовна',
      model: 'mod020',
      colorOptions: ['джинс'],
    },
    {
      id: 21,
      name: 'Літній комплект зі спідницею та топом',
      price: 850,
      category: 'Костюми',
      image: '/assets/images/mod496/beige1.jpg',
      colors: {
        'бежевий': [
          '/assets/images/mod496/beige1.jpg',
          '/assets/images/mod496/beige2.jpg',
          '/assets/images/mod496/beige3.jpg',
          '/assets/images/mod496/beige4.jpg',
          '/assets/images/mod496/beige5.jpg',
          '/assets/images/mod496/beige6.jpg',
          '/assets/images/mod496/beige7.jpg',
          '/assets/images/mod496/beige8.jpg',
          '/assets/images/mod496/beige9.jpg',
          '/assets/images/mod496/beige10.jpg',
          '/assets/images/mod496/beige11.jpg',
          '/assets/images/mod496/beige12.jpg',
          '/assets/images/mod496/beige13.jpg',
          '/assets/images/mod496/beige14.jpg',
        ],
        'чорний': [
          '/assets/images/mod496/black1.jpg',
          '/assets/images/mod496/black2.jpg',
          '/assets/images/mod496/black3.jpg',
          '/assets/images/mod496/black4.jpg',
          '/assets/images/mod496/black5.jpg',
          '/assets/images/mod496/black6.jpg',
          '/assets/images/mod496/black7.jpg',
          '/assets/images/mod496/black8.jpg',
          '/assets/images/mod496/black9.jpg',
          '/assets/images/mod496/black10.jpg',
        ],
        'барбі': [
          '/assets/images/mod496/barbie1.jpg',
          '/assets/images/mod496/barbie2.jpg',
          '/assets/images/mod496/barbie3.jpg',
          '/assets/images/mod496/barbie4.jpg',
          '/assets/images/mod496/barbie5.jpg',
          '/assets/images/mod496/barbie6.jpg',
          '/assets/images/mod496/barbie7.jpg',
          '/assets/images/mod496/barbie8.jpg',
          '/assets/images/mod496/barbie9.jpg',
          '/assets/images/mod496/barbie10.jpg',
          '/assets/images/mod496/barbie11.jpg',
          '/assets/images/mod496/barbie12.jpg',
          '/assets/images/mod496/barbie13.jpg',
          '/assets/images/mod496/barbie14.jpg',
        ],
        'молоко': [
          '/assets/images/mod496/milk1.jpg',
          '/assets/images/mod496/milk2.jpg',
          '/assets/images/mod496/milk3.jpg',
          '/assets/images/mod496/milk4.jpg',
          '/assets/images/mod496/milk5.jpg',
          '/assets/images/mod496/milk6.jpg',
          '/assets/images/mod496/milk7.jpg',
          '/assets/images/mod496/milk8.jpg',
          '/assets/images/mod496/milk9.jpg',
          '/assets/images/mod496/milk10.jpg',
          '/assets/images/mod496/milk11.jpg',
          '/assets/images/mod496/milk12.jpg',
        ],
      },
      description:
        'Легкий та романтичний літній комплект, що складається зі спідниці з воланами та топа з об\'ємними рукавами. Ідеальний вибір для спекотних літніх днів або прогулянок по пляжу.',
      sizes: '42-44, 46-48',
      fabric: 'жатка',
      model: 'mod496',
      colorOptions: ['бежевий', 'чорний', 'барбі', 'молоко'],
    },
    {
      id: 22,
      name: 'Романтичний комплект зі спідницею та топом',
      price: 1150,
      category: 'Костюми',
      image: '/assets/images/mod291/white_heart1.jpg',
      colors: {
        'білий серце': [
          '/assets/images/mod291/white_heart1.jpg',
          '/assets/images/mod291/white_heart2.jpg',
          '/assets/images/mod291/white_heart3.jpg',
          '/assets/images/mod291/white_heart4.jpg',
          '/assets/images/mod291/white_heart5.jpg',
          '/assets/images/mod291/white_heart6.jpg',
          '/assets/images/mod291/white_heart7.jpg',
          '/assets/images/mod291/white_heart8.jpg',
          '/assets/images/mod291/white_heart9.jpg',
          '/assets/images/mod291/white_heart10.jpg',
        ],
        'молоко квіти': [
          '/assets/images/mod291/milk_flowers1.jpg',
          '/assets/images/mod291/milk_flowers2.jpg',
          '/assets/images/mod291/milk_flowers3.jpg',
          '/assets/images/mod291/milk_flowers4.jpg',
          '/assets/images/mod291/milk_flowers5.jpg',
          '/assets/images/mod291/milk_flowers6.jpg',
          '/assets/images/mod291/milk_flowers7.jpg',
          '/assets/images/mod291/milk_flowers8.jpg',
        ],
      },
      description:
        'Ніжний та жіночний комплект, що складається з об\'ємного топа з оборками та пишної спідниці. Ідеально підходить для створення легкого та романтичного образу. Комплект прикрашений милим принтом, що додає шарму вашому стилю.',
      sizes: '42-44, 46-48',
      fabric: 'муслін принт',
      model: 'mod291',
      colorOptions: ['білий серце', 'молоко квіти'],
    },
    {
      id: 23,
      name: 'Комплект із зав\'язками та шортами',
      price: 770,
      category: 'Костюми',
      image: '/assets/images/mod299/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod299/black1.jpg',
          '/assets/images/mod299/black2.jpg',
          '/assets/images/mod299/black3.jpg',
        ],
        'молоко': [
          '/assets/images/mod299/milk1.jpg',
          '/assets/images/mod299/milk2.jpg',
          '/assets/images/mod299/milk3.jpg',
        ],
      },
      description:
        'Елегантний комплект, що складається з укороченого топа із зав\'язками та вільних шорт. Легкий і зручний, ідеально підходить для створення стильного та витонченого образу як на кожен день, так і для особливих випадків.',
      sizes: '42-44, 46-48',
      fabric: 'шовк армані',
      model: 'mod299',
      colorOptions: ['чорний', 'молоко'],
    },
    {
      id: 24,
      name: 'Oversize куртка з капюшоном',
      price: 750,
      category: 'Верхній одяг',
      image: '/assets/images/mod174/khaki1.jpg',
      colors: {
        'хакі': [
          '/assets/images/mod174/khaki1.jpg',
          '/assets/images/mod174/khaki2.jpg',
        ],
        'чорний': [
          '/assets/images/mod174/black1.jpg',
          '/assets/images/mod174/black2.jpg',
        ],
        'зелений': [
          '/assets/images/mod174/green1.jpg',
          '/assets/images/mod174/green2.jpg',
          '/assets/images/mod174/green1.jpg',
        ],
        'бежевий': [
          '/assets/images/mod174/beige1.jpg',
          '/assets/images/mod174/beige2.jpg',
        ],
      },
      description:
        'Стильна oversize куртка з капюшоном, ідеальна для прохолодних днів. Вільний крій забезпечує комфорт і універсальність, а практичні кишені та застібка-блискавка роблять її зручною в носінні.',
      sizes: '42-46, 48-52',
      fabric: 'плащівка Канада',
      model: 'mod174',
      colorOptions: ['хакі', 'чорний', 'зелений', 'бежевий'],
    },
    {
      id: 25,
      name: 'Стьобана куртка з накладними кишенями',
      price: 1100,
      category: 'Верхній одяг',
      image: '/assets/images/mod119/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod119/black1.jpg',
        ],
        'хакі': [
          '/assets/images/mod119/khaki1.jpg',
          '/assets/images/mod119/khaki2.jpg',
          '/assets/images/mod119/khaki3.jpg',
          '/assets/images/mod119/khaki4.jpg',
        ],
        'бежевий': [
          '/assets/images/mod119/beige1.jpg',
          '/assets/images/mod119/beige2.jpg',
        ],
      },
      description:
        'Стильна стьобана куртка з накладними кишенями та укороченим силуетом. Підходить для створення модних повсякденних образів, забезпечуючи комфорт і тепло в прохолодну погоду.',
      sizes: '42-44, 46-48',
      fabric: 'плащівка Канада (щільна)',
      model: 'mod119',
      colorOptions: ['чорний', 'хакі', 'бежевий'],
    },
    {
      id: 26,
      name: 'Осінній шарм',
      price: 900,
      category: 'Сорочки',
      image: '/assets/images/mod603/main1.jpg',
      colors: {
        'помаранчевий': [
          '/assets/images/mod603/orange1.jpg',
          '/assets/images/mod603/orange2.jpg',
        ],
        'червоний': [
          '/assets/images/mod603/red1.jpg',
          '/assets/images/mod603/red2.jpg',
          '/assets/images/mod603/red3.jpg',
        ],
        'сірий': [
          '/assets/images/mod603/gray1.jpg',
          '/assets/images/mod603/gray2.jpg',
          '/assets/images/mod603/gray3.jpg',
        ],
        'бордовий': [
          '/assets/images/mod603/burgundy1.jpg',
          '/assets/images/mod603/burgundy2.jpg',
        ],
      },
      description:
        'Ця стильна картата сорочка поєднує в собі комфорт і модний дизайн. Виготовлена з приємного на дотик матеріалу, вона ідеально підходить для повсякденного носіння. Сорочка має вільний крій, що забезпечує максимальний комфорт протягом дня.',
      sizes: '42-44, 46-48',
      fabric: 'фланель варена Туреччина',
      model: 'mod603',
      colorOptions: ['помаранчевий', 'червоний', 'сірий', 'бордовий'],
    },
    {
      id: 27,
      name: 'Фланелева мрія',
      price: 860,
      category: 'Сорочки',
      image: '/assets/images/mod022/main.jpg',
      colors: {
        'сірий': [
          '/assets/images/mod022/gray1.jpg',
          '/assets/images/mod022/gray2.jpg',
          '/assets/images/mod022/gray3.jpg',
        ],
        'жовтий з сірим': [
          '/assets/images/mod022/yellow_gray1.jpg',
          '/assets/images/mod022/yellow_gray2.jpg',
        ],
        'рожевий з сірим': [
          '/assets/images/mod022/pink_gray1.jpg',
          '/assets/images/mod022/pink_gray2.jpg',
        ],
        'синій': [
          '/assets/images/mod022/blue1.jpg',
          '/assets/images/mod022/blue2.jpg',
          '/assets/images/mod022/blue3.jpg',
        ],
      },
      description:
        'Фланелева сорочка оверсайз із м\'якої тканини, що створює відчуття затишку і комфорту. Ідеально підходить для осінньо-зимового сезону. Класичний клітчастий візерунок у поєднанні з актуальними кольорами надасть вашому образу стильний і сучасний вигляд.',
      sizes: '42-44, 46-48',
      fabric: 'фланель Туреччина',
      model: 'mod022',
      colorOptions: ['сірий', 'жовтий з сірим', 'рожевий з сірим', 'синій'],
    },
    {
      id: 28,
      name: 'Штани "Вільний стиль"',
      price: 720,
      category: 'Штани',
      image: '/assets/images/mod602/black1.jpg',
      colors: {
        'чорний': [
          '/assets/images/mod602/black1.jpg',
          '/assets/images/mod602/black2.jpg',
          '/assets/images/mod602/black3.jpg',
          '/assets/images/mod602/black4.jpg',
          '/assets/images/mod602/black5.jpg',
          '/assets/images/mod602/black6.jpg',
          '/assets/images/mod602/black7.jpg',
        ],
        'моко': [
          '/assets/images/mod602/light_gray1.jpg',
          '/assets/images/mod602/light_gray2.jpg',
          '/assets/images/mod602/light_gray3.jpg',
          '/assets/images/mod602/light_gray4.jpg',
          '/assets/images/mod602/light_gray5.jpg',
        ],
        'світло-сірий': [
          '/assets/images/mod602/moka1.jpg',
          '/assets/images/mod602/moka2.jpg',
          '/assets/images/mod602/moka3.jpg',
          '/assets/images/mod602/moka4.jpg',
          '/assets/images/mod602/moka5.jpg',
          '/assets/images/mod602/moka6.jpg',
        ],
      },
      description:
        'Стильні та зручні штани вільного крою з високою посадкою. Виготовлені з легкого та приємного на дотик матеріалу, вони ідеально підходять для активного способу життя та повсякденного носіння.',
      sizes: '42-44, 46-48',
      fabric: 'плащівка',
      model: 'mod602',
      colorOptions: ['чорний', 'світло-сірий', 'моко'],
    },
    {
      id: 29,
      name: 'Вечірня мелодія',
      price: 950,
      category: 'Сукні',
      image: '/assets/images/mod313/milk1.jpg',
      colors: {
        'молоко': [
          '/assets/images/mod313/milk1.jpg',
          '/assets/images/mod313/milk2.jpg',
          '/assets/images/mod313/milk3.jpg',
          '/assets/images/mod313/milk4.jpg',
        ],
        'червоний': [
          '/assets/images/mod313/red1.jpg',
          '/assets/images/mod313/red2.jpg',
          '/assets/images/mod313/red3.jpg',
          '/assets/images/mod313/red4.jpg',
          '/assets/images/mod313/red5.jpg',
        ],
      },
      description:
        'Елегантне плаття максі з оригінальними контрастними вставками, які надають образу яскравості та індивідуальності. Вільний крій та широкі рукави створюють ефект легкості й повітряності, а якісна тканина забезпечує комфорт протягом усього дня.',
      sizes: '42-44, 46-48, 50-52',
      fabric: 'льон',
      model: 'mod313',
      colorOptions: ['молоко', 'червоний'],
    },
    {
      id: 30,
      name: 'Сучасна класика',
      price: 1250,
      category: 'Сукні',
      image: '/assets/images/mod300/red_black1.jpg',
      colors: {
        'червоний + чорний': [
          '/assets/images/mod300/red_black1.jpg',
          '/assets/images/mod300/red_black2.jpg',
          '/assets/images/mod300/red_black3.jpg',
          '/assets/images/mod300/red_black4.jpg',
        ],
        'бежевий + мокко': [
          '/assets/images/mod300/beige_mokko1.jpg',
          '/assets/images/mod300/beige_mokko2.jpg',
          '/assets/images/mod300/beige_mokko3.jpg',
        ],
        'блакитний + білий': [
          '/assets/images/mod300/blue_white1.jpg',
          '/assets/images/mod300/blue_white2.jpg',
          '/assets/images/mod300/blue_white3.jpg',
        ],
      },
      description:
        'Елегантний комплект, що складається з топа з акцентними рукавами та довгої плісированої спідниці. Топ прикрашений стильними збірками на плечах, що додає образу особливого шарму. Спідниця виконана з легкої тканини, яка гарно струменить і підкреслює силует.',
      sizes: '42-44, 46-48, 50-52',
      fabric: 'костюмна тканина + софт',
      model: 'mod300',
      colorOptions: ['червоний + чорний', 'бежевий + мокко', 'блакитний + білий'],
    },
    {
      id: 31,
      name: 'Спортивний костюм',
      price: 1300,
      category: 'Спортивні костюми',
      image: '/assets/images/mod515/graphite1.jpg',
      colors: {
        'графіт': [
          '/assets/images/mod515/graphite1.jpg',
          '/assets/images/mod515/graphite2.jpg',
          '/assets/images/mod515/graphite3.jpg',
        ],
        'бежевий': [
          '/assets/images/mod515/beige1.jpg',
          '/assets/images/mod515/beige2.jpg',
          '/assets/images/mod515/beige3.jpg',
          '/assets/images/mod515/beige4.jpg',
          '/assets/images/mod515/beige5.jpg',
          '/assets/images/mod515/beige6.jpg',
          '/assets/images/mod515/beige7.jpg',
        ],
        'мокко': [
          '/assets/images/mod515/mokka1.jpg',
          '/assets/images/mod515/mokka2.jpg',
          '/assets/images/mod515/mokka3.jpg',
          '/assets/images/mod515/mokka4.jpg',
          '/assets/images/mod515/mokka5.jpg',
          '/assets/images/mod515/mokka6.jpg',
          '/assets/images/mod515/mokka7.jpg',
        ],
        'чорний': [
          '/assets/images/mod515/black1.jpg',
          '/assets/images/mod515/black2.jpg',
          '/assets/images/mod515/black3.jpg',
        ],
      },
      description: `
        В цьому костюмі ідеально все. Мʼякий, стильний, кофта на затяжках (можна регулювати довжину) та дуже теплий. 
        Це просто Must have твого гардеробу.
      `,
      sizes: '42-44, 46-48',
      fabric: 'барашек на велюрі',
      model: 'mod515',
      colorOptions: ['графіт', 'бежевий', 'мокко', 'чорний'],
    },
    {
      id: 32,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 900,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod5021/main234.jpg', // Основное изображение
      colors: {
        'чорний': [
          '/assets/images/mod5021/black1.jpg',
          '/assets/images/mod5021/black2.jpg',
        ],
        'графіт': [
          '/assets/images/mod5021/graphite1.jpg',
          '/assets/images/mod5021/graphite2.jpg',
          '/assets/images/mod5021/graphite3.jpg',
        ],
        'мокко': [
          '/assets/images/mod5021/mokka1.jpg',
          '/assets/images/mod5021/mokka2.jpg',
          '/assets/images/mod5021/mokka3.jpg',
          '/assets/images/mod5021/mokka4.jpg',
        ],
      },
      description: `
        Найстильніші та найзручніші костюми. Толстовка oversize + джогери.
        Резинка на кофті дозволяє регулювати довжину. 
        З ним ти обов'язково зігрієшся в холодну погоду та створиш супер стильні образи.
        Накат як на фото ☺️ Це просто Must have твого гардеробу.
      `,
      sizes: '42-44, 46-48',
      fabric: 'тринитка на флісі',
      model: 'mod5021',
      colorOptions: ['чорний', 'графіт', 'мокко'],
    },
    {
      id: 33,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 920,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod432/black1.jpg', // Основное изображение (черный за замовчуванням)
      colors: {
        'чорний': [
          '/assets/images/mod432/black1.jpg',
          '/assets/images/mod432/black2.jpg',
          '/assets/images/mod432/black3.jpg',
          '/assets/images/mod432/black4.jpg',
        ],
        'білий': [
          '/assets/images/mod432/white1.jpg',
          '/assets/images/mod432/white2.jpg',
          '/assets/images/mod432/white3.jpg',
          '/assets/images/mod432/white4.jpg',
          '/assets/images/mod432/white5.jpg',
        ],
        'шоколадний': [
          '/assets/images/mod432/chocolate1.jpg',
          '/assets/images/mod432/chocolate2.jpg',
          '/assets/images/mod432/chocolate3.jpg',
          '/assets/images/mod432/chocolate4.jpg',
          '/assets/images/mod432/chocolate5.jpg',
        ],
        'морська хвиля': [
          '/assets/images/mod432/sea_wave1.jpg',
          '/assets/images/mod432/sea_wave2.jpg',
          '/assets/images/mod432/sea_wave3.jpg',
          '/assets/images/mod432/sea_wave4.jpg',
          '/assets/images/mod432/sea_wave5.jpg',
          '/assets/images/mod432/sea_wave6.jpg',
        ],
      },
      description: `
        Найстильніші та найзручніші костюми. Толстовка oversize + джогери Карго.
        Резинка на кофті дозволяє регулювати довжину. 
        З ним ти обов'язково зігрієшся в холодну погоду та створиш супер стильні образи.
        Накат як на фото.
      `,
      sizes: '42-44, 46-48',
      fabric: 'тринитка на флісі',
      model: 'mod432',
      colorOptions: ['чорний', 'білий', 'шоколадний', 'морська хвиля'],
    },
    {
      id: 34,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 950,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod516/black1.jpg', // Основное изображение (черный за замовчуванням)
      colors: {
        'чорний': [
          '/assets/images/mod516/black1.jpg',
        ],
        'сірий меланж': [
          '/assets/images/mod516/gray_melange1.jpg',
        ],
        'рожевий': [
          '/assets/images/mod516/pink1.jpg',
        ],
        'рожевий+сірий': [
          '/assets/images/mod516/pink_gray1.jpg',
        ],
        'молоко та бежевий': [
          '/assets/images/mod516/milk_beige1.jpg',
        ],
      },
      description: `
        Найстильніші та найзручніші костюми. Толстовка oversize + джогери.
        Резинка на кофті дозволяє регулювати довжину. 
        З ним ти обов'язково зігрієшся в холодну погоду та створиш супер стильні образи.
      `,
      sizes: '42-44, 46-48',
      fabric: 'тринитка на флісі',
      model: 'mod516',
      colorOptions: ['чорний', 'сірий меланж', 'рожевий', 'рожевий+сірий', 'молоко та бежевий'],
    },
    {
      id: 35,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 850,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod257/trava1.jpg', // Основное изображение (трава за замовчуванням)
      colors: {
        'трава': [
          '/assets/images/mod257/trava1.jpg',
          '/assets/images/mod257/trava2.jpg',
          '/assets/images/mod257/trava3.jpg',
        ],
        'мокко': [
          '/assets/images/mod257/mokka1.jpg',
          '/assets/images/mod257/mokka2.jpg',
        ],
        'чорний': [
          '/assets/images/mod257/black1.jpg',
          '/assets/images/mod257/black2.jpg',
        ],
        'ізумрудний': [
          '/assets/images/mod257/emerald1.jpg',
        ],
        'бежевий': [
          '/assets/images/mod257/beige1.jpg',
          '/assets/images/mod257/beige2.jpg',
          '/assets/images/mod257/beige3.jpg',
          '/assets/images/mod257/beige4.jpg',
        ],
        'графітовий': [
          '/assets/images/mod257/graphite1.jpg',
          '/assets/images/mod257/graphite2.jpg',
          '/assets/images/mod257/graphite3.jpg',
          '/assets/images/mod257/graphite4.jpg',
        ],
      },
      description: `
        Базові костюми ніколи не вийдуть з моди. Найстильніші та найтепліші костюми на холодну пору. 
        В якому ти обов'язково зігрієшся в холодну погоду та створиш супер стильні образи.
      `,
      sizes: '42-44, 46-48, 50-52',
      fabric: 'тринитка на флісі',
      model: 'mod257',
      colorOptions: ['трава', 'мокко', 'чорний', 'ізумрудний', 'бежевий', 'графітовий'],
    },
    {
      id: 36,  // Уникальный ID товара
      name: 'Спортивні костюми',
      price: 980,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod517/red1.jpg', // Основное изображение (красный за замовчуванням)
      colors: {
        'червоний': [
          '/assets/images/mod517/red1.jpg',
          '/assets/images/mod517/red2.jpg',
          '/assets/images/mod517/red3.jpg',
          '/assets/images/mod517/red4.jpg',
          '/assets/images/mod517/red5.jpg',
          '/assets/images/mod517/red6.jpg',
          '/assets/images/mod517/red7.jpg',
          '/assets/images/mod517/red8.jpg',
        ],
        'графіт': [
          '/assets/images/mod517/graphite1.jpg',
          '/assets/images/mod517/graphite2.jpg',
          '/assets/images/mod517/graphite3.jpg',
          '/assets/images/mod517/graphite4.jpg',
        ],
        'бежевий': [
          '/assets/images/mod517/beige1.jpg',
          '/assets/images/mod517/beige2.jpg',
          '/assets/images/mod517/beige3.jpg',
        ],
      },
      description: `
        Костюм, який стане незамінним в твоєму гардеробі. 
        Базовий, стильний та комфортний.
      `,
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка на флісі',
      model: 'mod517',
      colorOptions: ['червоний', 'графіт', 'бежевий'],
    },
    {
      id: 37,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 900,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod323/black1.jpg', // Основное изображение (черный за замовчуванням)
      colors: {
        'чорний': [
          '/assets/images/mod323/black1.jpg',
          '/assets/images/mod323/black2.jpg',
          '/assets/images/mod323/black3.jpg',
          '/assets/images/mod323/black4.jpg',
        ],
        'білий': [
          '/assets/images/mod323/white1.jpg',
          '/assets/images/mod323/white2.jpg',
        ],
        'морська хвиля': [
          '/assets/images/mod323/sea_wave1.jpg',
          '/assets/images/mod323/sea_wave2.jpg',
          '/assets/images/mod323/sea_wave3.jpg',
        ],
      },
      description: `
        Шалено теплий ... м'який ... і такий приємний на дотик.
        Костюмчик на флісі.
        WOW посадка з акцентом на талії.
        У ньому все, як ви любите.
        Накат як на фото.
      `,
      sizes: '42-44, 46-48',
      fabric: 'тринитка на флісі',
      model: 'mod323',
      colorOptions: ['чорний', 'білий', 'морська хвиля'],
    },
    {
      id: 38,  // Уникальный ID товара
      name: 'Спортивні костюми',
      price: 1000,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod617/graphite1.jpg', // Основное изображение (графит за замовчуванням)
      colors: {
        'графіт': [
          '/assets/images/mod617/graphite1.jpg',
          '/assets/images/mod617/graphite2.jpg',
          '/assets/images/mod617/graphite3.jpg',
          '/assets/images/mod617/graphite4.jpg',
        ],
        'молоко': [
          '/assets/images/mod617/milk1.jpg',
          '/assets/images/mod617/milk2.jpg',
          '/assets/images/mod617/milk3.jpg',
          '/assets/images/mod617/milk4.jpg',
          '/assets/images/mod617/milk5.jpg',
          '/assets/images/mod617/milk6.jpg',
          '/assets/images/mod617/milk7.jpg',
          '/assets/images/mod617/milk8.jpg',
        ],
        'мокко': [
          '/assets/images/mod617/mokka1.jpg',
          '/assets/images/mod617/mokka2.jpg',
          '/assets/images/mod617/mokka3.jpg',
          '/assets/images/mod617/mokka4.jpg',
          '/assets/images/mod617/mokka5.jpg',
          '/assets/images/mod617/mokka6.jpg',
          '/assets/images/mod617/mokka7.jpg',
        ],
      },
      description: `
        Костюм, який точно тебе зігріє ❤️
      `,
      sizes: '42-44, 46-48',
      fabric: 'тринитка на флісі',
      model: 'mod617',
      colorOptions: ['графіт', 'молоко', 'мокко'],
    },
    {
      id: 39,  // Уникальный ID товара
      name: 'Сукня',
      price: 780,
      category: 'Сукні', // Категорія товару
      image: '/assets/images/mod445/graphite1.jpg', // Основное изображение (графіт за замовчуванням)
      colors: {
        'графіт': [
          '/assets/images/mod445/graphite1.jpg',
          '/assets/images/mod445/graphite2.jpg',
          '/assets/images/mod445/graphite3.jpg',
        ],
        'бежевий': [
          '/assets/images/mod445/beige1.jpg',
          '/assets/images/mod445/beige2.jpg',
          '/assets/images/mod445/beige3.jpg',
        ],
        'мокко': [
          '/assets/images/mod445/mokka1.jpg',
          '/assets/images/mod445/mokka2.jpg',
        ],
      },
      description: `
        Додай жіночності в свій гардероб. Наша стильна трикотажна сукня підійде під всі твої образи.
      `,
      sizes: '42-44, 46-48',
      fabric: 'рубчик',
      model: 'mod445',
      colorOptions: ['графіт', 'бежевий', 'мокко'],
    },
    {
      id: 40,  // Уникальный ID товара
      name: 'Сукня',
      price: 850,
      category: 'Сукні', // Категорія товару
      image: '/assets/images/mod256/black1.jpg', // Основное изображение (черный за замовчуванням)
      colors: {
        'чорний': [
          '/assets/images/mod256/black1.jpg',
          '/assets/images/mod256/black2.jpg',
          '/assets/images/mod256/black3.jpg',
        ],
      },
      description: `
        Сукня в якій ти виглядатимеш на мільйон. Ідеально підкреслює фігуру, а об’ємні рукава додають вишуканості в ваш образ.
      `,
      sizes: '42-44, 46-48',
      fabric: 'креп дайвінг + органза',
      model: 'mod256',
      colorOptions: ['чорний'],
    },
    {
      id: 41,  // Уникальный ID товара
      name: 'Сукня',
      price: 800,
      category: 'Сукні', // Категорія товару
      image: '/assets/images/mod614/powder1.jpg', // Основное изображение (пудра за замовчуванням)
      colors: {
        'пудра': [
          '/assets/images/mod614/powder1.jpg',
          '/assets/images/mod614/powder2.jpg',
        ],
        'моко': [
          '/assets/images/mod614/mokka1.jpg',
          '/assets/images/mod614/mokka2.jpg',
          '/assets/images/mod614/mokka3.jpg',
        ],
        'чорний': [
          '/assets/images/mod614/black1.jpg',
          '/assets/images/mod614/black2.jpg',
        ],
      },
      description: `
        Додай жіночності в свій гардероб. Наша стильна трикотажна сукня підійде під всі твої образи. Завʼязка на спині підкреслить твою талію.
      `,
      sizes: '42-44, 46-48',
      fabric: 'рубчик широкий',
      model: 'mod614',
      colorOptions: ['пудра', 'моко', 'чорний'],
    },
    {
      id: 42,  // Уникальный ID товара
      name: 'Сукня',
      price: 870,
      category: 'Сукні', // Категорія товару
      image: '/assets/images/mod615/white1.jpg', // Основное изображение (белый цвет за замовчуванням)
      colors: {
        'білий': [
          '/assets/images/mod615/white1.jpg',
          '/assets/images/mod615/white2.jpg',
          '/assets/images/mod615/white3.jpg',
          '/assets/images/mod615/white4.jpg',
          '/assets/images/mod615/white5.jpg',
        ],
      },
      description: `
        Ти точно не залишишся без уваги. Наша стильна трикотажна сукня підійде під всі твої образи.
      `,
      sizes: '42-44, 46-48',
      fabric: 'вʼязка Туреччина',
      model: 'mod615',
      colorOptions: ['білий'],
    },
    {
      id: 43,  // Уникальный ID товара
      name: 'Сорочка',
      price: 870,
      category: 'Сорочки', // Категорія товару
      image: '/assets/images/mod606/leopard1.jpg', // Основное изображение (леопардовый цвет по умолчанию)
      colors: {
        'леопардовий': [
          '/assets/images/mod606/leopard1.jpg',
          '/assets/images/mod606/leopard2.jpg',
          '/assets/images/mod606/leopard3.jpg',
          '/assets/images/mod606/leopard4.jpg',
          '/assets/images/mod606/leopard5.jpg',
        ],
        'зелений': [
          '/assets/images/mod606/green1.jpg',
          '/assets/images/mod606/green2.jpg',
          '/assets/images/mod606/green3.jpg',
          '/assets/images/mod606/green4.jpg',
          '/assets/images/mod606/green5.jpg',
        ],
        'хаки': [
          '/assets/images/mod606/khaki1.jpg',
          '/assets/images/mod606/khaki2.jpg',
          '/assets/images/mod606/khaki3.jpg',
          '/assets/images/mod606/khaki4.jpg',
          '/assets/images/mod606/khaki5.jpg',
        ],
        'моко': [
          '/assets/images/mod606/mokka1.jpg',
          '/assets/images/mod606/mokka2.jpg',
          '/assets/images/mod606/mokka3.jpg',
          '/assets/images/mod606/mokka4.jpg',
          '/assets/images/mod606/mokka5.jpg',
        ],
        'пудра': [
          '/assets/images/mod606/powder1.jpg',
          '/assets/images/mod606/powder2.jpg',
          '/assets/images/mod606/powder3.jpg',
          '/assets/images/mod606/powder4.jpg',
          '/assets/images/mod606/powder5.jpg',
        ],
      },
      description: `
        Твій новий об'єкт бажання. Сорочка оригінального крою, яка зараз знаходиться на самому піку популярності. 
        А розслаблений крій і дуже гарна палітра відтінків - абсолютне кохання всіх сучасних дівчат. 
        Вона 100% стане справжнісіньким хітом.
      `,
      sizes: '42-44, 46-48',
      fabric: 'кашемір утеплений Турція',
      model: 'mod606',
      colorOptions: ['леопардовий', 'зелений', 'хаки', 'моко', 'пудра'],
    },
    {
      id: 44,  // Уникальный ID товара
      name: 'Кофта',
      price: 750,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod610/black1.jpg', // Основное изображение (черный цвет по умолчанию)
      colors: {
        'чорний': [
          '/assets/images/mod610/black1.jpg',
        ],
        'молоко': [
          '/assets/images/mod610/milk1.jpg',
        ],
        'світло бежевий': [
          '/assets/images/mod610/light_beige1.jpg',
        ],
        'сірий': [
          '/assets/images/mod610/gray1.jpg',
        ],
        'темно-синій': [
          '/assets/images/mod610/dark_blue1.jpg',
        ],
      },
      description: `
        Ідеальний вибір на осінь-зиму – базовий світшот. 
        Доповнить будь-який ваш образ та зігріє в прохолодні дні.
      `,
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка на флісі',
      model: 'mod610',
      colorOptions: ['чорний', 'молоко', 'світло бежевий', 'сірий', 'темно-синій'],
    },
    {
      id: 45,  // Уникальный ID товара
      name: 'Кофта',
      price: 800,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod608/black1.jpg', 
      colors: {
        'чорний': [
          '/assets/images/mod608/black1.jpg',
        ],
        'шоколадний': [
          '/assets/images/mod608/chocolate1.jpg',
        ],
        'меланж': [
          '/assets/images/mod608/melange1.jpg',
        ],
        'джинс': [
          '/assets/images/mod608/denim1.jpg',
        ],
      },
      description: `
        Ідеальний вибір на осінь-зиму – базовий світшот. 
        Доповнить будь-який ваш образ та зігріє в прохолодні дні.
      `,
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка на флісі',
      model: 'mod608',
      colorOptions: ['чорний', 'шоколадний', 'меланж', 'джинс'],
    },
    {
      id: 46,  // Уникальный ID товара
      name: 'Кофта',
      price: 830,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod607/beige1.jpg', // Основное изображение (бежевый цвет по умолчанию)
      colors: {
        'бежевий': [
          '/assets/images/mod607/beige1.jpg',
          '/assets/images/mod607/beige2.jpg',
        ],
        'чорний': [
          '/assets/images/mod607/black1.jpg',
          '/assets/images/mod607/black2.jpg',
        ],
        'сірий': [
          '/assets/images/mod607/gray1.jpg',
          '/assets/images/mod607/gray2.jpg',
        ],
        'темно-синій': [
          '/assets/images/mod607/dark_blue1.jpg',
          '/assets/images/mod607/dark_blue2.jpg',
        ],
      },
      description: `
        Ідеальний вибір на осінь-зиму – худі на молнії. 
        Стильний накат не залишить вас без уваги.
        Доповнить будь-який ваш образ та зігріє в прохолодні дні.
      `,
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка на флісі',
      model: 'mod607',
      colorOptions: ['бежевий', 'чорний', 'сірий', 'темно-синій'],
    },
    {
      id: 47,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 940,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod027/melange1.jpg', // Основное изображение (меланж по умолчанию)
      colors: {
        'меланж': [
          '/assets/images/mod027/melange1.jpg',
        ],
        'чорний': [
          '/assets/images/mod027/black1.jpg',
        ],
      },
      description: `
        Комфортний та максимально стильний костюм. 
        Худі oversize на молнії та вільні штани виглядають дуже стильно.
      `,
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка на флісі',
      model: 'mod027',
      colorOptions: ['меланж', 'чорний'],
    },
    {
      id: 48,  // Уникальный ID товара
      name: 'Спортивний костюм',
      price: 840,
      category: 'Спортивні костюми', // Категорія товару
      image: '/assets/images/mod028/black1.jpg', // Основное изображение (черный по умолчанию)
      colors: {
        'чорний': [
          '/assets/images/mod028/black1.jpg',
          '/assets/images/mod028/black2.jpg',
          '/assets/images/mod028/black3.jpg',
          '/assets/images/mod028/black4.jpg',
          '/assets/images/mod028/black5.jpg',
          '/assets/images/mod028/black6.jpg',
        ],
        'меланж': [
          '/assets/images/mod028/melange1.jpg',
          '/assets/images/mod028/melange2.jpg',
          '/assets/images/mod028/melange3.jpg',
          '/assets/images/mod028/melange4.jpg',
        ],
        'бежевий': [
          '/assets/images/mod028/beige1.jpg',
          '/assets/images/mod028/beige2.jpg',
          '/assets/images/mod028/beige3.jpg',
          '/assets/images/mod028/beige4.jpg',
          '/assets/images/mod028/beige5.jpg',
          '/assets/images/mod028/beige6.jpg',
        ],
      },
      description: `
        Костюм, який стане незамінним в твоєму гардеробі. 
        Базовий, стильний та комфортний.
      `,
      sizes: '42-44, 46-48',
      fabric: 'трьохнитка на флісі',
      model: 'mod028',
      colorOptions: ['чорний', 'меланж', 'бежевий'],
    },
    {
      id: 49,  // Уникальный ID товара
      name: 'Костюм',
      price: 1150,
      category: 'Костюми', // Категорія товару
      image: '/assets/images/mod612/black1.jpg', // Основное изображение (черный по умолчанию)
      colors: {
        'чорний': [
          '/assets/images/mod612/black1.jpg',
          '/assets/images/mod612/black2.jpg',
          '/assets/images/mod612/black3.jpg',
          '/assets/images/mod612/black4.jpg',
          '/assets/images/mod612/black5.jpg',
        ],
        'сірий': [
          '/assets/images/mod612/gray1.jpg',
          '/assets/images/mod612/gray2.jpg',
        ],
        'молоко': [
          '/assets/images/mod612/milk1.jpg',
        ],
      },
      description: `
        Неймовірно затишний костюмчик. 
        Зігріє та подарує найгармонійніший образ.
        Штани та кофта oversize доповнять ваш осінній аутфіт.
      `,
      sizes: '42-44, 46-48',
      fabric: 'ангора рубчик',
      model: 'mod612',
      colorOptions: ['чорний', 'сірий', 'молоко'],
    },
    {
      id: 50,  // Уникальный ID товара
      name: 'Костюм',
      price: 1150,
      category: 'Костюми', // Категорія товару
      image: '/assets/images/mod613/black1.jpg', // Основное изображение (черный по умолчанию)
      colors: {
        'чорний': [
          '/assets/images/mod613/black1.jpg',
          '/assets/images/mod613/black2.jpg',
          '/assets/images/mod613/black3.jpg',
        ],
        'молоко': [
          '/assets/images/mod613/milk1.jpg',
        ],
        'шоколад': [
          '/assets/images/mod613/chocolate1.jpg',
          '/assets/images/mod613/chocolate2.jpg',
        ],
      },
      description: `
        Неймовірно затишний костюмчик. 
        Зігріє та подарує найгармонійніший образ.
        Штани та кофта oversize доповнять ваш осінній аутфіт.
      `,
      sizes: '42-44, 46-48',
      fabric: 'ангора рубчик',
      model: 'mod613',
      colorOptions: ['чорний', 'молоко', 'шоколад'],
    },
  ];

  const filteredProducts = products.filter(
    (product) =>
      (!selectedCategory || product.category === selectedCategory) &&
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOpenModal = (product: any) => {
    setSelectedProduct(product);
    setCurrentImageIndex(0);

    if (product.colorOptions && product.colorOptions.length > 0) {
      setSelectedColor(product.colorOptions[0]);
    }
    setModalOpen(true);
    setDescriptionOpen(false);
  };

  const handleCloseModal = () => {
    setClosingModal(true);
    setTimeout(() => {
      setModalOpen(false);
      setClosingModal(false);
    }, 300);
  };

  const handleAddToCart = () => {
    if (selectedProduct) {
      addToCart({
        id: selectedProduct.id,
        name: selectedProduct.name,
        price: selectedProduct.price,
        image: selectedProduct.image, // Передаем изображение по умолчанию
        video: selectedProduct.image.includes('.mp4') ? selectedProduct.image : undefined,
        color: selectedColor,
        model: selectedProduct.model,
      });
      handleCloseModal();
    }
  };

  const handlePrevImage = () => {
    if (selectedProduct && selectedProduct.colors && selectedProduct.colors[selectedColor]) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? selectedProduct.colors[selectedColor].length - 1 : prevIndex - 1
      );
    }
  };

  const handleNextImage = () => {
    if (selectedProduct && selectedProduct.colors && selectedProduct.colors[selectedColor]) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === selectedProduct.colors[selectedColor].length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handleDropdownClose = () => {
    setClosingDropdown(true);
    setTimeout(() => {
      setDropdownOpen(false);
      setClosingDropdown(false);
    }, 300);
  };

  const handleColorChange = (color: string) => {
    setSelectedColor(color);
    setCurrentImageIndex(0);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    handleDropdownClose();
  };

  const clearCategoryFilter = () => {
    setSelectedCategory(null);
  };

  return (
    <CatalogContainer>
      <h1>Каталог</h1>

      <SearchAndFilterContainer>
        <SearchInput
          type="text"
          placeholder="Пошук товару..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FilterButtonContainer>
          <FilterButton onClick={() => setDropdownOpen(!isDropdownOpen)}>
            <FaFilter style={{ marginRight: '10px' }} />
            Фільтрування за категоріями
          </FilterButton>
          <FilterDropdown $isOpen={isDropdownOpen} $closing={closingDropdown}>
            <CloseButton onClick={handleDropdownClose}>
              <FaTimes />
            </CloseButton>
            {categories.map((category) => (
              <FilterOption key={category} onClick={() => handleCategorySelect(category)}>
                {category}
              </FilterOption>
            ))}
            <FilterOption onClick={clearCategoryFilter}>Скинути фільтр</FilterOption>
          </FilterDropdown>
        </FilterButtonContainer>
      </SearchAndFilterContainer>

      {selectedCategory && <p>Обрана категорія: {selectedCategory}</p>}

      <ProductsGrid>
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} onClick={() => handleOpenModal(product)}>
            {product.image && product.image.includes('.mp4') ? (
              <ProductVideo src={product.image} autoPlay muted loop />
            ) : (
              product.image && <ProductImage src={product.image} alt={product.name} />
            )}
            <ProductName>{product.name}</ProductName>
            <ProductPrice>{product.price} грн</ProductPrice>
            <BuyButton onClick={() => handleOpenModal(product)}>Деталі</BuyButton>
          </ProductCard>
        ))}
      </ProductsGrid>

      {/* Модальное окно */}
      {isModalOpen && selectedProduct && (
        <ModalBackground $isOpen={isModalOpen}>
          <ModalContent $closing={closingModal} $isMobile={window.innerWidth <= 768}>
            <ImagesContainer>
              <ArrowButton onClick={handlePrevImage}>
                <FaChevronLeft />
              </ArrowButton>
              {selectedProduct.colors[selectedColor][currentImageIndex].includes('.mp4') ? (
                <ProductModalVideo
                  src={selectedProduct.colors[selectedColor][currentImageIndex]}
                  autoPlay
                  muted
                  loop
                />
              ) : (
                <ProductImages
                  src={selectedProduct.colors[selectedColor][currentImageIndex]}
                  alt={selectedProduct.name}
                />
              )}
              <ArrowButton onClick={handleNextImage}>
                <FaChevronRight />
              </ArrowButton>
            </ImagesContainer>

            <ProductDescription>
              <h2>{selectedProduct.name}</h2>
              <ProductDetails $isOpen={descriptionOpen}>
                {selectedProduct.description}
              </ProductDetails>
              <ProductDetailsBold>Ціна: {selectedProduct.price} грн</ProductDetailsBold>
              <ProductDetailsBold>Розміри: {selectedProduct.sizes}</ProductDetailsBold>
              <ProductDetailsBold>Модель: {selectedProduct.model}</ProductDetailsBold>
              {selectedProduct.fabric && (
                <ProductDetailsBold>Тканина: {selectedProduct.fabric}</ProductDetailsBold>
              )}
              <ColorOptions>
                {selectedProduct.colorOptions.map((color: string) => (
                  <ColorOption
                    key={color}
                    $isSelected={selectedColor === color}
                    $isAvailable={selectedProduct.colors[color].length > 0}
                    onClick={() => handleColorChange(color)}
                  >
                    {color}
                  </ColorOption>
                ))}
              </ColorOptions>
              {window.innerWidth <= 768 && (
                <DescriptionToggleButton
                  onClick={() => setDescriptionOpen(!descriptionOpen)}
                >
                  {descriptionOpen ? 'Приховати опис' : 'Показати опис'}
                </DescriptionToggleButton>
              )}
              <BuyButton onClick={handleAddToCart}>Додати до кошику</BuyButton>
            </ProductDescription>
            <CloseModalButton onClick={handleCloseModal}>
              <FaTimes />
            </CloseModalButton>
          </ModalContent>
        </ModalBackground>
      )}
    </CatalogContainer>
  );
};

export default Catalog;