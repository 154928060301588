import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import Contacts from './pages/Contacts';
import About from './pages/About';
import Catalog from './pages/Catalog';
import Cart from './pages/Cart'; 
import { CartProvider } from './context/CartContext'; 

const App: React.FC = () => {
  return (
    <CartProvider>
      <Router>
        <GlobalStyles />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/about" element={<About />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/cart" element={<Cart />} /> {/* маршрут для корзины */}
        </Routes>
      </Router>
    </CartProvider>
  );
};

export default App;
